import React from 'react'
import cx from 'classnames'

import styles from './LiveFeed.module.scss'

const LiveFeed = ({ extraInfos, className }) => {
  const { performerId } = extraInfos
  const feedUrl = `//pt.protawe.com/live-feed/?c=awe_livefeed_container&site=jasmin&cobrandId=&psid=DeeperStudio&pstool=202_1&psprogram=revs&campaign_id=&forcedPerformers[0]=${performerId}&vp[showChat]=false&vp[chatAutoHide]=false&vp[showCallToAction]=false&vp[showPerformerName]=true&vp[showPerformerStatus]=true&filters=&subAffId={SUBAFFID}&embedTool=1&origin=`

  return (
    <div id='awe_livefeed_container' className={cx(styles.liveFeedContainer, className)}>
      <iframe title='Live Feed' src={feedUrl} className={styles.liveFeed} />
    </div>
  )
}

export default LiveFeed