import React, { useState } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'
import * as timeago from 'timeago.js'

import VideoCardThumbnail from 'components/VideoCardThumbnail'

import styles from './VideoCard.module.scss'

const Card = ({ data, displayRow }) => {

  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = () => setIsHovering(true)
  const handleMouseLeave = () => setIsHovering(false)

  const videoCardClasses = classNames(styles.videoCard, { [styles.displayRow]: displayRow })

  return (
    <article className={videoCardClasses} >
      <div className={styles.thumbnailContainer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onTouchStart={handleMouseEnter} onTouchEnd={handleMouseLeave} >
        <VideoCardThumbnail
          slug={data.slug}
          thumbnail={data.thumbnail}
          thumbnailAlt={data.thumbnailAlt}
          preview={data.preview}
          duration={data.duration}
          isHd={true}
          isHovering={isHovering}
        />
      </div>

      <div className={styles.details}>
        {!displayRow && 
          <Link to={`/models/${data.model.slug}/`} className={styles.modelProfilePicture} title={data.model.primaryAka.nickname}>
            <Img fluid={{...data.model.profilePicture.childImageSharp.fluid, aspectRatio: 1 }} alt={`${data.model.primaryAka.nickname} profile picture`} className={styles.modelProfilePictureImg} />
          </Link>
        }
        <div className={styles.metadata}>
          <Link to={`/videos/${data.slug}/`} className={classNames(styles.title, {[styles.titleHovered]: isHovering})}>
            <h1 title={data.title}>{data.title}</h1>
          </Link>
          <div className={styles.modelNickname}>
            <Link to={`/models/${data.model.slug}/`}>{data.model.primaryAka.nickname}</Link>
          </div>
          <div className={styles.line}>
            <div className={styles.views}>{data.views.toLocaleString()} views</div>
            <div className='date'><time dateTime={data.publishDate}>{timeago.format(data.publishDate)}</time></div>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Card