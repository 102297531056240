import React, { useEffect } from 'react'

const ChatHeadTool = () => {
  
  useEffect(() => {
    const scriptTag = document.createElement('script')
    scriptTag.async = false
    scriptTag.defer = true
    scriptTag.src = `//awecre.com/embed/natlf?c=ChatHeadTool&site=jasmin&cobrandId=&psid=DeeperStudio&pstool=321_1&psprogram=revs&campaign_id=&category=girl&position=br&offsetX=20&offsetY=20&vp[showChat]=false&vp[chatAutoHide]=true&vp[showCallToAction]=false&vp[showPerformerName]=true&vp[showPerformerStatus]=true&filters=&ms_notrack=1&subAffId={SUBAFFID}`
    const body = document.getElementsByTagName('body')[0]
    body.appendChild(scriptTag)

    return () => {
      body.removeChild(scriptTag)
    }
  }, [])

  return (
    <div id="ChatHeadTool"></div>
  )
  
}

export default ChatHeadTool