import React, { useState, useEffect } from 'react'

import styles from './Preview.module.scss'

const loadPreview = (path) => new Promise((resolve, reject) => {
  const img = new Image()
  img.onload = resolve.bind(path)
  img.onerror = reject
  img.src = path
})

const Preview = ({ previews, onLoad, shouldShow }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [currentPreview, setCurrentPreview] = useState(0)

  useEffect(() => {
    let isMounted = true

    Promise
      .all(previews.map(loadPreview))
      .then(() => {
        if (!isMounted) return;
        
        setIsLoading(false)
        onLoad()
      })

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (!shouldShow) return setCurrentPreview(0);
    const nextPreview = (currentPreview + 1) % previews.length
    const id = setTimeout(() => setCurrentPreview(nextPreview), 500)
    return () => clearTimeout(id)
  }, [currentPreview, shouldShow])

  if (isLoading) return null;

  return (
    <div className={styles.preview} style={ !shouldShow ? { display: 'none' } : null }>
    {previews.map((preview, idx) => 
      <img
        key={idx}
        src={preview}
        style={ idx !== currentPreview ? { display: 'none' } : null }
        alt='thumbnail'
      />
    )}
    </div>
  )
}

export default Preview