import React, { useContext, useMemo } from 'react'
import { graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import cx from 'classnames'
import StarRatings from 'react-star-ratings';

import { UserInfoContext } from 'utils/store'

import SEO from 'components/SEO'
import VideoCard from 'components/VideoCard'
import FindMeOn from './FindMeOn'
import LiveFeed from './LiveFeed'
import ChatHeadTool from 'components/ChatHeadTool'
import PartnersLinks from 'components/PartnersLinks'

import styles from './Model.module.scss'

const ModelPage = ({ data }) => {
  const userInfo = useContext(UserInfoContext)
  const { model, videos, site: { siteMetadata } } = data

  const breadcrumb = useMemo(() => ({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/models/`,
          "name": "Models"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${siteMetadata.siteUrl}/models/${model.slug}/`,
          "name": model.primaryAka.nickname
        }
      },
    ]
  }), [model.modelId])

  if (userInfo && model.bannedCountries.includes(userInfo.country_code)) {
    navigate('/country-block/')
    return null;
  }
  
  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`${model.seoTitle} | SloppyDeep.com`}
      description={model.seoDescription}
      keywords={model.seoKeywords.join(', ')}
      imagePath={model.profilePicture.publicURL}
      imageAlt={''}
      pagePath={`/models/${model.slug}/`}
      robots='index, follow'
    />
    <Helmet>
      { /* prefetch for thumbnails */}
      <link rel='preconnect dns-prefetch' href='https://galleryn0.awemwh.com' crossorigin />
    </Helmet>
    <main className={cx(styles.modelScreen, 'screen-container')}>
      <div className={styles.header}>
        <div className={styles.profilePicture}>
          <Img fluid={{ ...model.profilePicture.childImageSharp.fluid, aspectRatio: 1 }} className={styles.profilePictureImg} />
        </div>
        <section className={styles.headerTop}>
          <div className={styles.nicknameContainer}>
            <div className={styles.nickname}><h1>{model.primaryAka.nickname}</h1></div>
            <img loading='lazy' className={styles.flag} src={`/assets/images/flags/${model.countryCode}.jpg`} title={model.countryCode} alt={`${model.countryCode} flag`} />
          </div>
          
          <div className={styles.rating}>
            <StarRatings
              rating={model.rating}
              starEmptyColor="#C4C4C4"
              starRatedColor="#FFB800"
              starDimension="1.25rem"
              starSpacing=".1rem"
            />
          </div>
        </section>
        <p className={styles.headerMiddle}>{model.description}</p>
        <section className={styles.headerBottom}>
          <div className={styles.infoItem}>
            <span className={styles.infoText}>{model.orientation}</span>
            <span className={styles.infoType}>orientation</span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.infoText}>{model.ethnicity}</span>
            <span className={styles.infoType}>enthinicity</span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.infoText}>{model.build}</span>
            <span className={styles.infoType}>build</span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.infoText}>{model.breastSize}</span>
            <span className={styles.infoType}>breast size</span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.infoText}>{model.age}</span>
            <span className={styles.infoType}>age</span>
          </div>
        </section>
      </div>

      <section className={styles.latestVideosBlock}>
        <h1 className={styles.titleBlock}>Latest videos</h1>
        <div className={`${styles.contentBlock} video-cards-container`}>
          {videos.nodes.map((video, idx) => <VideoCard key={idx} data={video} />)}
        </div>
      </section>

      <div className={styles.feedsBlock}>

        {model.primaryAka.camSite === 'livejasmin.com' && 
          <section className={styles.liveFeedBlock}>
            <h1 className={styles.titleBlock}>Live feed</h1>
            <div className={styles.contentBlock}>
              <LiveFeed extraInfos={model.primaryAka.extraInfos} />
            </div>
          </section>
        }

        <section className={styles.findMeOnBlock}>
          <h1 className={styles.titleBlock}>Find me on</h1>
          <div className={styles.contentBlock}>
            {model.aka.map((aka, idx) => <FindMeOn key={idx} modelAka={aka} />)}
          </div>
        </section>

      </div>

      {model.partnersLinks.length > 0 &&
        <aside className={styles.partnersLinksBlock}>
          <h1 className={styles.titleBlock}>Sloppy Partners</h1>
          <div className={styles.contentBlock}>
            <PartnersLinks links={model.partnersLinks} />
          </div>
        </aside>
      }
      
    </main>
    <ChatHeadTool />
    </>
  )
}

export const query = graphql`
  query ModelPageQuery($id: String!) {

    model: sloppyDeepModel(id: {eq: $id}) {
      slug
      modelId
      age
      aka {
        camSite
        extraInfos
        nickname
      }
      bannedCountries
      breastSize
      build
      countryCode
      description
      ethnicity
      orientation
      primaryAka {
        nickname
        camSite
        extraInfos
      }
      profilePicture {
        publicURL
        childImageSharp {
          fluid(maxWidth: 230, maxHeight: 230, quality: 80, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rating
      seoDescription
      seoKeywords
      seoTitle
      partnersLinks
    }

    videos: allSloppyDeepVideo(
      filter: {model: {id: {eq: $id}}},
      sort: {order: DESC, fields: publishDate}
    ) {
      nodes {
        slug
        title
        views
        publishDate
        duration
        isHd
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 380, maxHeight: 214, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        thumbnailAlt
        preview
        model {
          slug
          primaryAka {
            nickname
          }
          profilePicture {
            childImageSharp {
              fluid(maxWidth: 50, maxHeight: 50, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

export default ModelPage
