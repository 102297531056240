import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Preview from './Preview'

import styles from './Thumbnail.module.scss'

function fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s}

const Thumbnail = ({ slug, thumbnail, thumbnailAlt, preview, duration, isHd, isHovering }) => {
  const [hoveredAtLeastOnce, SetHoveredAtLeastOnce] = useState(false)
  const [isPreviewLoaded, setIsPreviewLoaded] = useState(false)

  const handlePreviewLoad = () => setIsPreviewLoaded(true)

  useEffect(() => {
    if (isHovering && !hoveredAtLeastOnce) SetHoveredAtLeastOnce(true)
  }, [isHovering, hoveredAtLeastOnce])

  return (
    <Link className={styles.thumbnail} to={`/videos/${slug}/`}>
      <Img fluid={{ ...thumbnail.childImageSharp.fluid, aspectRatio: 16/9 }} alt={thumbnailAlt} style={isHovering && isPreviewLoaded ? {display: 'none'} : null}/>
      
      { hoveredAtLeastOnce &&
        <Preview
          previews={preview}
          onLoad={handlePreviewLoad}
          shouldShow={isHovering && isPreviewLoaded}
        />
      }

      { isHd && <div className={styles.isHd}>HD</div>}
      <div className={styles.duration}>{fmtMSS(duration)}</div>
    </Link>
  )
}

export default Thumbnail