import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cx from 'classnames'

import styles from './FindMeOn.module.scss'

const useCamSiteLogo = (camSite) => {
  const { logos } = useStaticQuery(graphql`
    query LogosQuery {
      logos: allFile(
        filter: {
          relativeDirectory: {regex: "/cam-sites/"},
          name: {eq: "square-logo"}
        }
      ) {
        nodes {
          relativeDirectory
          publicURL
        }
      }
    }
  `)
  
  const filtered = logos.nodes.filter(logo => logo.relativeDirectory === `cam-sites/${camSite}`)
  return filtered.length === 1 ? filtered[0].publicURL : null;
}

const getLivejasminStatus = async ({ performerId }) => {
  const livejasminStatus = await fetch(`//pt.protoawe.com/api/model/status?performerId=${performerId}&responseFormat=&psId=DeeperStudio&accessKey=26f9e9fed088612faa843fbdeb9c4baa&template=&legacyRedirect=1`)
    .then(fetchResult => fetchResult.text())

  const status = {}

  if (livejasminStatus === 'free_chat') {
    status.type = 'green'
  } else if (livejasminStatus === 'offline') {
    status.type = 'gray'
  } else {
    status.type = 'yellow'
  }

  status.text = livejasminStatus.replace('_', ' ')

  return status
}

const getRefLink = (camSite, { performerId }) => {

  switch(camSite) {
    case 'livejasmin.com':
      return `https://aweptjmp.com/?params[utm_source]=livefeed&params[utm_medium]=webmaster&params[utm_campaign]=main&params[utm_content]=&params[psid]=DeeperStudio&params[jumpReferrer]=www.awempire.com&params[pstool]=202_1&params[psprogram]=revs&params[campaign_id]=&cobrandId=&pageName=freechat&performerName=${performerId}&categoryName=girl&superCategoryName=girls&siteId=jasmin`
    
    case 'cams.com':
      return `https://cams.com/${performerId}`

    case 'streamate.com':
      return `https://streamate.com/cam/${performerId}`

    case 'chaturbate.com':
      return `https://chaturbate.com/${performerId}/`

    case 'myfreecams.com':
      return `https://www.myfreecams.com/#${performerId}`

    default: return null
  }

}

const useModelStatus = (camSite, extraInfos) => {
  const [status, setStatus] = useState({ type: 'yellow', text: 'checking' })

  useEffect(() => {
    if (camSite === 'livejasmin.com') getLivejasminStatus(extraInfos).then(setStatus)
  }, [camSite, extraInfos])

  return status
};



const FindMeOn = ({ modelAka }) => {
  const { nickname, camSite, extraInfos } = modelAka
  const status = useModelStatus(camSite, extraInfos)
  const camSiteLogo = useCamSiteLogo(camSite)
  const refLink = getRefLink(camSite, extraInfos)

  return (
    <a className={styles.findMeOn} href={refLink} aria-label={camSite} target='_blank' rel='noopener'>
      <img src={camSiteLogo} className={styles.camSiteLogo} title={camSite} alt={`${camSite} Logo`} />
      <p className={styles.info}>
        <span className={styles.nickname}>{nickname}</span>
        <span className={cx(styles.status, styles[`${status.type}Status`])} >{status.text}</span>
      </p>
    </a> 
  )
  
}

export default FindMeOn